<nav class="navbar navbar-expand-lg navbar-dark bg-dark position-fixed w-100 z-index custom-navbar">
  <a class="navbar-brand">{{isEnglish ? 'Luiz Loyola\'s Resume' : 'Currículo de Luiz Loyola'}}</a>
  <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          class="navbar-toggler"
          data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a (click)="download('pdf')" class="nav-link" href="#">
          <fa-icon [icon]="faFilePdf"></fa-icon>
          <span class="ml-2">{{ isEnglish ? 'Save PDF' : 'Salvar PDF'}}</span>
        </a>
      </li>
      <li class="nav-item">
        <a (click)="download('docx')" class="nav-link" href="#">
          <fa-icon [icon]="faFileWord"></fa-icon>
          <span class="ml-2">{{ isEnglish ? 'Save DOCX' : 'Salvar DOCX'}}</span>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a (click)="changeLanguage($event)" class="nav-link language-link" href="#">
          <img class="flag {{isEnglish ? 'd-none' : ''}}" src="https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg" alt="">
          <img class="flag {{isEnglish ? '' : 'd-none'}}" src="https://catamphetamine.gitlab.io/country-flag-icons/3x2/BR.svg" alt="">
          <span class="ml-2">{{ isEnglish ? 'Mudar para português' : 'Change to English'}}</span>
        </a>
      </li>
    </ul>
  </div>
</nav>
<div class="container padding">
  <pdf-viewer *ngIf="!loading" [src]="pdfSource"></pdf-viewer>
  <ngx-loading [show]="loading"></ngx-loading>
  <p class="footer-text">© Luiz Loyola - 2022</p>
</div>
