import {Component} from '@angular/core';
import {faFilePdf, faFileWord} from '@fortawesome/free-solid-svg-icons';
import {ApiService} from './api.service';
import * as fileSaver from 'file-saver';
import {ActivatedRoute, Route} from '@angular/router';


type Extension = 'docx' | 'pdf';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  faFileWord = faFileWord;
  faFilePdf = faFilePdf;

  isEnglish: boolean = false;

  loading = true;
  files: Array<{name: string, version: number, isEnglish: boolean, extension: Extension}>;
  get faLanguage() {
    return this.isEnglish ? faFileWord : faFilePdf;
  }

  get pdfSource(): string {
    return this.apiService.getUrl(this.getLatest('pdf').name);
  }

  constructor(private apiService: ApiService) {
    const urlParams = new URLSearchParams(window.location.search);
    this.isEnglish = urlParams.get('lang') === 'en';

    apiService.getFiles().subscribe(data => {
      this.loading = false;
      this.parseFiles(<Array<object>> data);
    });
  }

  download(extension: Extension) {
    const file = this.getLatest(extension);
    this.apiService.downloadFile(file.name).subscribe(data => {
      let blob = new Blob([data], {type: 'application/octet-stream'});
      window.URL.createObjectURL(blob);
      fileSaver.saveAs(blob, file.name);
    });
  }

  private parseFiles(data: Array<object>) {

    const nameRegex = /Currículo Luiz v(\d+)( - EN)?\.(docx|pdf)/;

    this.files = data.map(file => {
      const name = file['name'];
      const match = nameRegex.exec(name);
      if (match) {
        const version = parseInt(match[1]);
        const isEnglish = match[2] !== undefined;
        const extension = match[3];
        return {
          name,
          version,
          isEnglish,
          extension: extension as Extension
        }
      }
    }).filter(file => file);
  }

  private getLatest(ext: 'docx' | 'pdf') {
    return this.files.filter(file => file.extension === ext && file.isEnglish === this.isEnglish).sort((a, b) => b.version - a.version)[0];
  }

  changeLanguage(e: MouseEvent) {
    e.preventDefault();

    this.isEnglish = !this.isEnglish;

    // change url without reloading
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('lang', this.isEnglish ? 'en' : 'pt');
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
  }
}
