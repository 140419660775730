import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  // private urlBase = "files";
  private urlBase = "https://curriculo.luizloyola.com.br/files";

  getUrl(fileName: string): string {
    return `${this.urlBase}/${fileName}`;
  }

  getFiles() {
    return this.http.get(this.urlBase);
  }

  downloadFile(fileName: string) {
    return this.http.get(this.getUrl(fileName),{ responseType: 'blob' })
  }
}
